<template>
    <section class="makro p-16 shadow" style="background-color:#F8F8F8">
        <h1 class="text-4xl font-bold">{{ makro.title }}</h1>
        <div class="text-2xl leading-normal mt-10" v-html="makro.content">
        </div>
    </section>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        mounted() {
            this.getMakro(this.$route.params.id);
        },
        methods: {
            ...mapActions(['getMakro']),
        },
        computed: {
            ...mapGetters(['makro'])
        }
    }
</script>